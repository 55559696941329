import {computed, signal} from '@preact/signals';

const {__} = wp.i18n;

export const distanceChoices = [
    __('Distanciel', 'oxiane-institut'),
    __('Présentiel', 'oxiane-institut'),
];

export const sessions = signal(window.OxianeInstitut.formationSessions || []);
export const distance = signal(distanceChoices[1]);
export const date = signal('');

export const dates = computed(() => {
    return sessions.value.map(session => session.date);
});
