import MicroModal from 'micromodal';
import {date} from '../stores/reservationForm';
import {modalConfig} from '../utils/modal';

export class Formation {
    constructor(options) {
        this.options = Object.assign({}, {
            showButtonFormation: '#formation-show-more',
            formationList: '#formation-list',
            reservation: '.reservation',
        }, options);
    }

    bind(elem) {
        this.elem = elem;

        this.setupReservationModal();
        this.sessionsShowMore();

        const isMobile = window.matchMedia('(max-width: 1024px)');
        if (isMobile.matches) {
            this.mobileToggleSection();
        }

        document.addEventListener('wpcf7submit', event => {
            const modal = event.target.closest('.modal');
            if (modal) {
                setTimeout(() => modal.scrollTo(0, modal.scrollHeight), 300);
            }
        });
    }

    setupReservationModal() {
        this.elem.querySelectorAll(this.options.reservation).forEach(button => {
            button.addEventListener('click', () => {
                MicroModal.show('modal-reservation', modalConfig);
                const card = button.closest('.card-session');
                const session = JSON.parse(card.dataset.session);
                date.value = session.date;
            });
        });
    }

    sessionsShowMore() {
        const showButtonFormation = this.elem.querySelector(this.options.showButtonFormation);
        if (!showButtonFormation) {
            return;
        }
        const formationList = this.elem.querySelector(this.options.formationList);
        showButtonFormation.addEventListener('click', () => {
            showButtonFormation.classList.toggle('up');
            formationList.classList.toggle('formation-extract');
        });
    }

    mobileToggleSection() {
        let activeSection = null;
        const sections = document.querySelectorAll('.formation-accordeon');
        const toggle = section => {
            if (activeSection === section) {
                section.classList.remove('formation-accordeon-open');
                activeSection = null;
                return;
            }
            activeSection = section;
            sections.forEach(s => s.classList.toggle('formation-accordeon-open', s === activeSection));
        };
        sections.forEach(section => {
            const title = section.querySelector('.subitem-title');
            title.addEventListener('click', event => {
                event.preventDefault();
                toggle(section);
            });
        });
    }
}

