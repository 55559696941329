import {BurgerMenu} from './BurgerMenu';
import {Filter} from './Filter';
import {Formation} from './Formation';
import {GtmPush} from './GtmPush';
import {LangSwitcher} from './LangSwitcher';
import {ModalInfoForms} from './ModalInfoForms';
import {ReservationFormModal} from './ReservationFormModal';
import {Reviews} from './Reviews';
import {SearchModal} from './SearchModal';
import {SearchResults} from './SearchResults';
import {ShowMoreFooter} from './ShowMoreFooter';
import {Slider} from './Slider';
import {Stars} from './Stars';
import {Popin} from "./Popin";

export const plugins = {
    BurgerMenu,
    Filter,
    Formation,
    GtmPush,
    ReservationFormModal,
    Reviews,
    SearchModal,
    SearchResults,
    ShowMoreFooter,
    Slider,
    Stars,
    LangSwitcher,
    ModalInfoForms,
    Popin,
};
