export class Popin {
  constructor(options) {

  }
  bind(elem) {
    this.handlePopin();
  }
  handlePopin()
  {
    console.log('test');
    // Initialize Variables
    let popinsContainer = document.querySelectorAll('.section--cta-popin');
    console.log(popinsContainer);
    if (popinsContainer) {
      Array.from(popinsContainer).forEach( popin => {
        let button  = popin.querySelector('.btn-popin');
        let close   = popin.querySelector('#popupclose');
        let overlay = popin.querySelector("#overlay");
        let popup   = popin.querySelector('#popup');

        if (overlay) {
          // Close Popup Event
          overlay.addEventListener('click', function (e) {
            if ( overlay.classList.contains('show') ) {
              overlay.classList.remove('show');
            }
            if ( popup.classList.contains('show') ) {
              popup.classList.remove('show');
            }
          });
        }
        if (close) {
          // Close Popup Event
          close.addEventListener('click', function (e) {
            if ( overlay.classList.contains('show') ) {
              overlay.classList.remove('show');
            }
            if ( popup.classList.contains('show') ) {
              popup.classList.remove('show');
            }
          });
        }
        // Show Overlay and Popup
        button.addEventListener('click', function (e) {
          overlay.classList.add('show');
          popup.classList.add('show');
        });
      });
    }
  }
}
